import PropTypes from 'prop-types';
import React from 'react';

import './header.scss';

const Header = ({ siteTitle }) => (
  <header className="header">
    <span className="header__site-title">{siteTitle}</span>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
